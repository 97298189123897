<template>
  <div class="model-info" ref="showModelRef">
    <div class="pull-icon" @touchstart="starMove">
      <div class="icon"></div>
    </div>
    <div class="top-info-title" v-if="itemTitle == 'Features'">
      <div class="left-line"></div>
      <div class="title-txt">
        {{ itemTitle }}
      </div>
    </div>
    <div class="bot-info-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import bus from "@/lib/bus.js";
export default {
  data() {
    return {
      detailNumList: {
        lawn: 10,
        wall: 11,
        Balcony: 11,
      },
      test: "",
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$parent?.$refs?.detailTab?.meshes?.[this.$store.state.mesh])
        this.test = this.$parent.$refs.detailTab.meshes[
          this.$store.state.mesh
        ].length;
    });
  },
  computed: {
    itemTitle() {
      return this.$parent.itemTitle;
    },
  },
  methods: {
    px2NowPx(num) {
      return ((num / 3.75) * window.innerWidth) / 100;
    },
    starMove(e) {
      // 最小的高度  threeTopRef是顶部的tab栏 切换使用
      let threeTabTop =
        this.$parent.$refs.threeTopRef.getBoundingClientRect().top +
        this.px2NowPx(88);

      let minPx = this.px2NowPx(23); // 最小的 高度23px
      let midPx = this.px2NowPx(356); // 最大的 高度356px

      let dom = this.$refs.showModelRef; // 下面的详情栏

      dom.style.transition = "none";

      let { clientY } = e.targetTouches[0];

      let lastDistance;

      let moveEvent = (event) => {
        let newClientY = event.targetTouches[0].clientY; // 鼠标距离顶部的高度
        let distance = newClientY - clientY; // 滑动 高度差
        let domHeight = dom.offsetHeight; // 详情栏 盒子实时的高度
        let topDis = dom.getBoundingClientRect().top; // 详情栏  距离顶部距离
        clientY = newClientY;
        if (
          domHeight - distance < minPx ||
          (topDis <= threeTabTop && distance <= 0)
        )
          return;

        if (
          this.$store.state.nowTab == "Parameter" ||
          distance > 0 ||
          (domHeight < 356 && this.$store.state.nowTab !== "Structure")
        ) {
          dom.style.height = domHeight - distance + "px";
          lastDistance = distance;
        }

        // bus.$emit("self-adaption");

        // bus.$emit("setSceneSize");

        // bus.$emit("setScenePosition", percentage);
      };

      let moveEnd = () => {
        window.removeEventListener("touchmove", moveEvent);
        //  lastDistance 向下为正数 向上为负数
        dom.style.transition = "height 0.2s";
        if (this.$store.state.nowTab == "Parameter") {
          if (lastDistance > 0) {
            if (dom.offsetHeight > 356) {
              dom.style.height = `${midPx}px`;
              bus.$emit(
                "SwitchAccordingToStatus",
                this.$store.state.selectMesh,
                "height"
              );
              this.$store.state.isDown = false;
            } else {
              dom.style.height = `${minPx}px`;
              bus.$emit(
                "SwitchAccordingToStatus",
                this.$store.state.selectMesh,
                "low"
              );
              this.$store.state.isDown = true;
            }
          } else {
            if (dom.offsetHeight > 356) {
              let topdis = dom.getBoundingClientRect().top;
              let residueDis = topdis - threeTabTop;
              let nowDomHeight = dom.offsetHeight;
              dom.style.height = `${nowDomHeight + residueDis}px`;
            } else {
              dom.style.height = `${midPx}px`;

              bus.$emit(
                "SwitchAccordingToStatus",
                this.$store.state.selectMesh,
                "height"
              );
              this.$store.state.isDown = false;
            }
          }
        } else if (this.$store.state.nowTab == "Structure") {
        } else {
          if (lastDistance > 0) {
            dom.style.height = `${minPx}px`;

            bus.$emit(
              "SwitchAccordingToStatus",
              this.$store.state.selectMesh,
              "low"
            );
            this.$store.state.isDown = true;
          } else {
            dom.style.height = `${midPx}px`;

            bus.$emit(
              "SwitchAccordingToStatus",
              this.$store.state.selectMesh,
              "height"
            );
            this.$store.state.isDown = false;
          }
        }
        window.removeEventListener("touchend", moveEnd);
      };

      window.addEventListener("touchmove", moveEvent);
      window.addEventListener("touchend", moveEnd);
    },
  },
};
</script>

<style lang="scss" scoped>
.model-info {
  width: 100%;
  height: 356px;
  display: flex;
  flex-direction: column;
  .pull-icon {
    padding-top: 12px;
    padding-bottom: 6px;
    width: 100%;
    height: 23px;
    flex-shrink: 0;
    pointer-events: all;
    .icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 40px;
      height: 5px;
      background: #efefef;
      border-radius: 20px;
    }
  }
  .top-info-title {
    flex-shrink: 0;
    width: 100%;
    height: 43px;
    display: flex;
    align-items: center;
    background-color: #fff;
    .left-line {
      width: 5px;
      height: 25px;
      background: #e08c12;
      border-radius: 0px 2px 2px 0px;
    }
    .title-txt {
      margin-left: 11px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
    }
  }
  .bot-info-content {
    padding: 0 16px;
    width: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>
