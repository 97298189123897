<style lang="scss" scoped>
.index {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  background-color: #fff;

  .top-img {
    padding: 18px;
    width: 281.25px;
    // height: 372px;
    border-radius: 9px;
    background: #ffffff;
  }
  .bot-img {
    width: 100%;
    .img-bot-title {
      padding: 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.8);
    }
    .close {
      pointer-events: all;
      position: absolute;
      right: 4px;
      top: 6px;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .bot-link-content {
      width: 100%;
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: center;
      .left-btn {
        height: 74px;
        margin-right: 48px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 28px;
            height: 28px;
          }
        }
        .icon:active {
          background: rgba(0, 0, 0, 0.2);
          border-radius: 50%;
        }
        .text {
          margin-top: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          white-space: nowrap;
          color: rgba(0, 0, 0, 0.8);
        }
        // display: flex;
      }
      .right-btn {
        margin-right: 0px !important;
      }
    }
  }
  .p-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .popup-content {
    position: absolute;
    top: calc(50% - 76px);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 9px;
    z-index: 3000;
  }
  .popup-bot-content {
    border-radius: 12px 12px 0px 0px;
  }
  .content {
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .three-page {
      width: 100%;
      height: 100%;

      .top {
        z-index: 3;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        margin: 16px auto;
        padding: 4px;
        height: 32px;
        background: rgba(255, 255, 255, 0.8);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: all;
        .scene-btn {
          width: 88px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          .name {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        .active-name {
          background: #e08c12;
          border-radius: 20px;
          .name {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }

    .info {
      position: fixed;
      bottom: 0px;
      z-index: 100;
      pointer-events: all;
      width: 100%;
      height: auto;
      border-radius: 12px 12px 0px 0px;
      background: #ffffff;
      .bot-str-icon {
        position: absolute;
        top: -48px;
        left: 50%;
        transform: translateX(-50%);
        height: 32px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: all;
        .left-item {
          border-radius: 20px;
          border: 1px solid #595757;
          padding-left: 6px;
          padding-right: 16px;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
        }
        .back-icon {
          background-image: url("../../assets/three/re-ac.svg") !important;
        }
        .left-icon {
          width: 32px;
          height: 32px;
          background-image: url("../../assets/three/fen.svg");
          background-size: 100%;
        }
        .right-text {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #595757;
          white-space: nowrap;
        }
      }

      .bot-icon {
        position: absolute;
        top: -48px;
        right: 16px;
        width: 32px;
        height: 32px;
        // border-radius: 33.3333px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("../../assets/three/share.svg");
        background-size: 100%;
      }
      .bot-icon:active {
        background-image: url("../../assets/three/share-a.svg") !important;
      }
      .three-icon {
        position: absolute;
        top: -88px;
        right: 16px;
        width: 32px;
        height: 32px;
        border-radius: 33.3333px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .active-icon {
        background: #e8f3ff !important;
        color: #007aff;
      }
      .feature-tab {
        margin-top: 6px;
        width: 100%;
        .feature-item {
          display: flex;
          align-items: center;
        }
        .feat-icon {
          width: 20px;
          height: 20px;
          margin-right: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .feat-text {
          font-weight: 400;
          font-size: 13px;
          line-height: 32px;
          color: rgba(0, 0, 0, 0.6);
          white-space: nowrap;
        }
      }
      .param {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 81px;
  height: 78px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  display: flex;
  z-index: 10000;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loading-text {
    padding-top: 6px;
    font-style: normal;
    white-space: nowrap;
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
  }
}
.zhanwei {
  width: 100%;
  height: 44px;
}
</style>
<template>
  <div class="index">
    <cus-header></cus-header>
    <div class="zhanwei"></div>
    <div class="p-container" v-if="isShare">
      <div class="popup-content">
        <div class="top-img" ref="shareImgRef">
          <img-three></img-three>
        </div>
      </div>
    </div>

    <van-popup
      v-model="isShare"
      class="popup-bot-content"
      position="bottom"
      style="background:#fff;height:152px"
    >
      <div class="bot-img">
        <div class="img-bot-title">
          Share to
        </div>
        <div class="close" @click="close">
          <img src="@/assets/dialog/close.svg" alt="" />
        </div>
        <div class="bot-link-content">
          <div class="left-btn">
            <div class="icon" @click="copy">
              <img src="@/assets/dialog/link.svg" alt="" />
            </div>

            <div class="text">
              copy link
            </div>
          </div>
          <div class="left-btn right-btn">
            <div class="icon" @click="save">
              <img src="@/assets/dialog/save.svg" alt="" />
            </div>
            <div class="text">
              save the picture
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <div class="loading-icon" v-show="!isLoading">
      <van-loading type="spinner" size="20px" />
      <div class="loading-text">loading</div>
    </div>
    <div class="content" ref="centerContentRef">
      <div class="three-page" ref="threePage">
        <div class="top" ref="threeTopRef" v-show="isLoading">
          <div
            class="scene-btn"
            v-for="(item, index) of sceneName"
            @click="changeRoute(item)"
            :class="item == itemTitle ? 'active-name' : ''"
          >
            <div class="name">{{ item }}</div>
          </div>
        </div>
        <!-- 场景 -->
        <Scene @loading="loading"></Scene>
      </div>
      <div class="info" v-show="isLoading">
        <div
          class="bot-str-icon"
          v-if="itemTitle == 'Structure'"
          @click="dynamicClick"
        >
          <div class="left-item" v-if="isPlay">
            <div class="left-icon back-icon"></div>
            <div class="right-text">Return</div>
          </div>
          <div class="left-item" v-if="isDisplay">
            <div class="left-icon"></div>
            <div class="right-text">Dynamic Display</div>
          </div>
        </div>
        <div
          class="three-icon"
          v-if="itemTitle == 'Parameter'"
          :class="isSce ? 'active-icon' : ''"
          @click="changeParam"
        >
          <img :src="isSce ? img1 : img2" alt="" />
        </div>
        <div class="bot-icon" @click="share"></div>
        <info-model
          v-if="itemTitle == 'Features'"
          key="Features"
          ref="feaModel"
        >
          <div class="feature-tab">
            <div v-for="item in featureData" class="feature-item">
              <div class="feat-icon"><img :src="item.img" alt="" /></div>
              <p class="feat-text" v-for="content in item.datas">
                {{ content }}
              </p>
            </div>
          </div>
        </info-model>
        <info-model v-if="itemTitle == 'Parameter'" key="Parameter">
          <div class="param">
            <param-table></param-table>
          </div>
        </info-model>
        <info-model
          v-if="itemTitle == 'Structure'"
          :style="`height:${23 / 3.75}vw`"
        >
        </info-model>
      </div>
    </div>
  </div>
</template>

<script>
import Scene from "./../../components/three-scene/index.vue";
import ImgThree from "./components/img-three.vue";
import infoModel from "./components/info-model.vue";
import ParamTable from "./components/param-table.vue";
import html2canvas from "html2canvas";
import bus from "./../../lib/bus";
import { copyText } from "@/utils/index.js";
export default {
  components: { infoModel, ParamTable, ImgThree, Scene },
  data() {
    return {
      img1: require("@/assets/three/size-a.svg"),
      img2: require("@/assets/three/size.svg"),
      sceneName: ["Features", "Parameter", "Structure"],
      itemTitle: "Features",
      isSce: false,
      isShare: false,
      isPlay: false, //开启3d演示
      isDisplay: true,
      innerMeshChild: false,
      timer: null,
    };
  },
  mounted() {
    window.sizeInstance = this;
    bus.$on("three-icon-IsNormal", (isNormal) => {
      this.isSce = isNormal;
    });
    bus.$on("changeMeshBtn", () => {
      if (!this.isDisplay) {
        bus.$emit("resetAllNow");
      }
      this.structureEvent();
    });
  },
  watch: {
    isLoading() {
      if (this.$route.query && Object.keys(this.$route.query).length !== 0) {
        this.initShare(this.$route.query.mesh, this.$route.query.tab);
      }
    },
  },
  computed: {
    featureData() {
      let data;
      if (this.$store.state.meshType == "LFP9-30kWh/HV") {
        data = [
          {
            img: require("@/assets/features/1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("@/assets/features/2.svg"),
            datas: ["Home Automation"],
          },
          {
            img: require("@/assets/features/3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("@/assets/features/4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("@/assets/features/5.svg"),
            datas: ["Communication"],
          },
          {
            img: require("@/assets/features/6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("@/assets/features/7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("@/assets/features/8.svg"),
            datas: ["IP54 RatingTriple hardware protection"],
          },
          {
            img: require("@/assets/features/9.svg"),
            datas: ["Smart BMS system to optimize the performance"],
          },
        ];
      } else if (this.$store.state.meshType == "LFP 5-10KWH/LV") {
        data = [
          {
            img: require("@/assets/features/1-1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("@/assets/features/1-2.svg"),
            datas: ["Natural cooling system"],
          },
          {
            img: require("@/assets/features/1-3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("@/assets/features/1-4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("@/assets/features/1-5.svg"),
            datas: ["Scalable up to 160kWh 16 (Parallel)"],
          },
          {
            img: require("@/assets/features/1-6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("@/assets/features/1-7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("@/assets/features/1-8.svg"),
            datas: ["IP20 RatingTriple hardware protection"],
          },
          {
            img: require("@/assets/features/1-9.svg"),
            datas: ["Smart BMS system to optimize the performance"],
          },
        ];
      } else if (
        this.$store.state.meshType == "Single Phase" ||
        this.$store.state.meshType == "Three Phase"
      ) {
        data = [
          {
            img: require("@/assets/features/3-1.svg"),
            datas: ["Big lcd display"],
          },
          {
            img: require("@/assets/features/3-2.svg"),
            datas: ["Power export limit"],
          },
          {
            img: require("@/assets/features/3-3.svg"),
            datas: ["Excellent off grid ability"],
          },
          {
            img: require("@/assets/features/3-4.svg"),
            datas: ["Wifi/GPRS/Lan communication optional"],
          },
          {
            img: require("@/assets/features/3-5.svg"),
            datas: ["Quick and easy single person installation"],
          },
          {
            img: require("@/assets/features/3-6.svg"),
            datas: ["Comply with international quality standards tested"],
          },
          {
            img: require("@/assets/features/3-7.svg"),
            datas: ["IP65 ingress protection"],
          },
        ];
      } else if (this.$store.state.meshType == "TOPCON") {
        data = [
          {
            img: require("@/assets/features/4-1.svg"),
            datas: ["SMBB Technology Half Cut TOPCon Cell"],
          },
          {
            img: require("@/assets/features/4-2.svg"),
            datas: ["High Energy Performance"],
          },
          {
            img: require("@/assets/features/4-3.svg"),
            datas: ["100% Inspection 30years Guarantee"],
          },
          {
            img: require("@/assets/features/4-4.svg"),
            datas: ["Fire Class A"],
          },
          {
            img: require("@/assets/features/4-5.svg"),
            datas: ["Strengthened Mechanical Load"],
          },
          {
            img: require("@/assets/features/4-6.svg"),
            datas: ["Advanced Bifacial Efficiency"],
          },
        ];
      } else {
        data = [
          {
            img: require("@/assets/features/1-1.svg"),
            datas: ["High inverter compatibility"],
          },
          {
            img: require("@/assets/features/1-2.svg"),
            datas: ["Natural cooling system"],
          },
          {
            img: require("@/assets/features/1-3.svg"),
            datas: [">15 Years life span"],
          },
          {
            img: require("@/assets/features/1-4.svg"),
            datas: ["Reliable LFP cells"],
          },
          {
            img: require("@/assets/features/1-5.svg"),
            datas: ["Scalable up to 80kWh 16 (Parallel)"],
          },
          {
            img: require("@/assets/features/1-6.svg"),
            datas: ["Cells cycle times 6000 cycles"],
          },
          {
            img: require("@/assets/features/1-7.svg"),
            datas: ["CANbus standard connection"],
          },
          {
            img: require("@/assets/features/1-8.svg"),
            datas: ["IP20 RatingTriple hardware protection"],
          },
          {
            img: require("@/assets/features/1-9.svg"),
            datas: ["Smart BMS system to optimize the performance"],
          },
        ];
      }

      return data;
    },
    isLoading: {
      get() {
        return this.$store.state.isLoading;
      },
      set(newV) {
        this.$store.state.isLoading = newV;
      },
    },
  },
  methods: {
    loading() {
      this.isLoading = true;
    },
    initShare(mesh, tab) {
      this.changeRoute(tab);
      bus.$emit("changeMesh", mesh);
    },
    dynamicClick() {
      if (!this.isPlay) {
        bus.$emit("displayMesh", this.$store.state.selectMesh);
        this.isDisplay = false;
        this.timer = setTimeout(() => {
          this.isPlay = true;
          clearTimeout(this.timer);
          this.timer = null;
        }, 2000);
      } else if (!this.isDisplay) {
        bus.$emit("unDisplayMesh", this.$store.state.selectMesh);
        this.isPlay = false;
        this.timer = setTimeout(() => {
          this.isDisplay = true;
          clearTimeout(this.timer);
          this.timer = null;
        }, 2000);
      }
    },
    copy() {
      let nowHref = window.location.origin;
      let res1 = this.$store.state.selectName.replaceAll(" ", "");
      let res = res1.replace("·", "-");
      let urlShare = `${nowHref}/#/?mesh=${res}&&tab=${this.$store.state.nowTab.replaceAll(
        " ",
        ""
      )}`;
      const rst = copyText(urlShare);
      rst &&
        this.$toast.success({
          message: "Copy Successfully",
          className: "succ-width",
          duration: 2000,
        });
    },
    save() {
      html2canvas(this.$refs.shareImgRef, {
        backgroundColor: "#e8f4ff00",
        useCORS: true, // 如果截图的内容里有图片,可能会有跨域的情况,加上这个参数,解决文件跨域问题
      }).then((canvas) => {
        this.downloadIamge(canvas, "pic");
      });
    },
    downloadIamge(canvas, name) {
      //下载图片地址和图片名
      canvas.toBlob((blob) => {
        let a = document.createElement("a"); // 生成一个a元素
        a.download = name || "photo"; // 设置图片名称
        a.href = URL.createObjectURL(blob); // 将生成的URL设置为a.href属性
        a.click();
        a.remove();
        this.$toast.success({
          message: "Successfully Saved",
          className: "succ-width",
          duration: 2000,
        });
      }, "image/svg"); //得到图片的base64编码数据
    },
    changeParam() {
      this.isSce = !this.isSce;
      if (this.isSce) {
        bus.$emit("showSize", this.$store.state.selectMesh);
        this.$toast({
          message: "Display Size",
          className: "hidden-size",
          duration: 2000,
        });
      } else {
        bus.$emit("hiddenSize", this.$store.state.selectMesh);
        this.$toast({
          message: "Hidden Size",
          className: "hidden-size",
          duration: 2000,
        });
      }
    },
    structureEvent() {
      if (!this.isDisplay) {
        if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
        }
        this.isPlay = false;
        this.isDisplay = true;
      }
    },
    leaveStructure() {
      if (!this.isDisplay) {
        bus.$emit("resetAllNow");
      }
      this.structureEvent();
    },
    leaveParamter() {
      if (this.isSce) {
        bus.$emit("hiddenSize", this.$store.state.selectMesh);
        this.isSce = false;
      }
    },
    setTable(meshName) {
      this.itemTitle = meshName;
      this.$store.state.nowTab = meshName;
    },
    changeRoute(meshName) {
      this.setTable(meshName);
      if (meshName == "Structure") {
        bus.$emit(
          "SwitchAccordingToStatus",
          this.$store.state.selectMesh,
          "low"
        );
        this.$store.state.isDown = true;
        this.$store.state.isStructure = true;
      } else {
        bus.$emit(
          "SwitchAccordingToStatus",
          this.$store.state.selectMesh,
          "height"
        );
        this.$store.state.isDown = false;
        this.$store.state.isStructure = false;
      }
      this.leaveStructure();
      this.leaveParamter();
    },
    share() {
      this.isShare = true;
    },
    close() {
      this.isShare = false;
    },
  },
};
</script>
